import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'fast-modal-library';

const apiPrefix = process.env.REACT_APP_API_PREFIX;

/**
 * @file
 * @module Pages/ForgotPassword
 * @description ForgotPassword est une page qui permet à un utilisateur de réinitialiser son mot de passe.
 * - L'utilisateur doit saisir son adresse e-mail, puis un e-mail de réinitialisation de mot de passe est envoyé.
 * - Cette page utilise le hook useState de React pour gérer l'état du formulaire et de l'affichage de la modale.
 * - Cette page utilise le hook useEffect de React pour charger le script reCAPTCHA.
 * - Cette page utilise la fonction fetch de JavaScript pour envoyer une demande de réinitialisation de mot de passe à l'API.
 */


const ForgotPassword = () => {
    const [username, setUsername] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/enterprise.js?render=6LfxufopAAAAAMPi3djhkNECTCscbUN9s60zfAm6';
        script.async = true;
        document.head.appendChild(script);

        script.onload = () => {
            
        };

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    const hide = () => {
        setShowModal(false);
        navigate('/signin');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (window.grecaptcha && window.grecaptcha.enterprise) {
            window.grecaptcha.enterprise.ready(async () => {
                try {
                    const token = await window.grecaptcha.enterprise.execute('6LfxufopAAAAAMPi3djhkNECTCscbUN9s60zfAm6', { action: 'LOGIN' });

                    // console.log('Generated reCAPTCHA token:', token);

                    const response = await fetch(apiPrefix + 'forgot-password', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ username, token })
                    });

                    const data = await response.json();

                    if (response.ok) {
                        setMessage("Vous allez recevoir un mail vous indiquant la marche à suivre");
                    } else if (response.status === 400 && data.error === "Aucun utilisateur trouvé avec cette adresse e-mail") {
                        setMessage("Aucun utilisateur trouvé avec cette adresse e-mail");
                    } else {
                        setMessage(data.error || 'Une erreur est survenue');
                    }
                    setShowModal(true);
                } catch (error) {
                    setMessage('Une erreur est survenue lors de l\'envoi de la demande de réinitialisation du mot de passe.');
                    setShowModal(true);
                }
            });
        } else {
            setMessage('reCAPTCHA not ready or not available');
            setShowModal(true);
        }
    };

    return (
        <div className='forgotpass-container'>
            <h2>Réinitialiser le mot de passe</h2>
            <form onSubmit={handleSubmit}>
            <input
                type="text"
                placeholder="Votre adresse email"
                value={username}
                onChange={e => setUsername(e.target.value.trim())}
                required
            />

                <button type="submit">Envoyer l'e-mail de réinitialisation</button>
            </form>
            {message && <p className='error-message'>{message}</p>}
            {showModal && (
                <Modal contentModal={message} hide={hide} />
            )}
        </div>
    );
};

export default ForgotPassword;
